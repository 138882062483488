import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaShoppingCart } from 'react-icons/fa';
import '../css/ProductPage.css';
import emailjs from 'emailjs-com';
import { NiceSimpleCaptcha } from 'nice-simple-captcha';
import 'nice-simple-captcha/dist/index.css';
import RelatedProducts from './RelatedProducts';
import Subscribe from './Subscribe';
import SocialBar from './SocialBar';
import EndlessText from './EndlessText';
import Footer from './Footer';
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const ProductPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showMessage, setShowMessage] = useState(null); // Renamed state for success/error message
    const [formData, setFormData] = useState({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
    });
    const [captchaPassed, setCaptchaPassed] = useState(false);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const productResponse = await fetch(`http://${backendHost}/products/${id}`);
                if (productResponse.ok) {
                    const productData = await productResponse.json();
                    setProduct(productData);

                    // Fetch images
                    const imagesResponse = await fetch(`http://${backendHost}/products/${id}/images`);
                    if (imagesResponse.ok) {
                        const imagesData = await imagesResponse.json();
                        setImages(imagesData);
                    } else {
                        console.error('Images not found');
                    }
                } else {
                    console.error('Product not found');
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchProductData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (captchaPassed) {
            const formElement = e.target;
            const formDataWithProductDetails = new FormData(formElement);

            // Append product name and URL to the form data
            const productUrl = `http://www.jbembalagens.com.br/produto/${product.path}`;
            formDataWithProductDetails.append('nomeproduto', product.nome);
            formDataWithProductDetails.append('linkproduto', productUrl);

            // Convert FormData to a plain object for emailjs.send
            const formObject = {};
            formDataWithProductDetails.forEach((value, key) => {
                formObject[key] = value;
            });

            emailjs.send('service_ed56c7m', 'template_hfb7796', formObject, 'Tg0iGx5O7KywgchHs')
                .then((result) => {
                    setShowPopup(false);
                    setShowMessage({ type: 'success', text: 'Mensagem enviada! Responderemos o mais breve possível.' });
                    setFormData({
                        nome: '',
                        email: '',
                        telefone: '',
                        mensagem: ''
                    });
                }, (error) => {
                    setShowMessage({ type: 'error', text: 'Houve um erro ao enviar sua mensagem. Por favor, tente novamente.' });
                });
        } else {
            alert('Please complete the captcha.');
        }
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const captchaValidate = (passed, messageCode) => {
        setCaptchaPassed(passed);
        if (!passed) {
            console.error('Captcha validation failed:', messageCode);
        }
    };

    const onEnterPressed = () => {
        console.log('Enter pressed, captcha value accepted.');
    };

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div className="father-of-all">
        <Container className="product-page">
            <Row>
                <Col md={6}>
                    <div className="product-images">
                        {images.length > 0 && (
                            <Card className="main-image-card">
                                <Card.Img
                                    variant="top"
                                    src={"http://"+ backendHost + `${images[0]}`}
                                    alt={`Main Product Image`}
                                />
                            </Card>
                        )}
                        <div className="additional-images">
                            {images.slice(1).map((image, index) => (
                                <Card key={index} className="additional-image-card">
                                    <Card.Img
                                        variant="top"
                                        src={"http://"+ backendHost + `${image}`}
                                        alt={`Additional Product Image ${index + 1}`}
                                    />
                                </Card>
                            ))}
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="product-details">
                        <h1 className="product-title">{product.nome}</h1>
                        <h2 className="product-price">R$ {product.preco}</h2>
                        <div
                            className="product-description"
                            dangerouslySetInnerHTML={{ __html: product.descricao }}
                        />
                        <div className="product-actions">
                            <Button variant="primary" className="btn-add-to-cart" onClick={openPopup}>
                                <FaShoppingCart /> Solicitar Orçamento
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
    
            {/* Popup Form */}
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h3>Por favor, preencha seus dados</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="nome">Nome:</label>
                                <input
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="telefone">Telefone:</label>
                                <input
                                    type="tel"
                                    id="telefone"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mensagem">Observação? </label>
                                <textarea
                                    id="mensagem"
                                    name="mensagem"
                                    value={formData.mensagem}
                                    onChange={handleChange}
                                    rows="5"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <NiceSimpleCaptcha
                                    className={''}
                                    inputClassName={''}
                                    screenColor='white'
                                    screenBorderColor='black'
                                    captchaColor='black'
                                    characters={'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789'}
                                    length={5}
                                    crossLine={true}
                                    expireAfterSec={60}
                                    caseSensetive={true}
                                    style={{ width: '200px' }}
                                    refreshButtonIcon={null}
                                    onCaptchaValidate={captchaValidate}
                                    onEnterPressed={onEnterPressed}
                                />
                            </div>
                            <div className="button-cont">
                                <button type="submit">Enviar</button>
                                <button type="button" onClick={closePopup}>Fechar</button>
                            </div>
                        </form>
                    </div>

                </div>
            )}
    
            {/* Related Products Component */}
            
        </Container>
            <RelatedProducts productName={product.nome} />
        <Subscribe />
            <SocialBar />
            <Footer />
            <EndlessText></EndlessText>
        </div>
    );
    
};

export default ProductPage;
