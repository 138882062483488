// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Carrousel.css */
.large-banner {
    height: 280px;
    background-color: #ffcc00; /* Example background color */
    width: 100vw;
    overflow:hidden;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: 100%;
    height: 100%;
}

.random-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .large-banner {
        height: 430px;
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Carrousel.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,aAAa;IACb,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;QACb,WAAW;IACf;AACJ","sourcesContent":["/* Carrousel.css */\n.large-banner {\n    height: 280px;\n    background-color: #ffcc00; /* Example background color */\n    width: 100vw;\n    overflow:hidden;\n}\n\n.swiper-container {\n    width: 100%;\n    height: 100%;\n}\n\n.swiper-slide {\n    width: 100%;\n    height: 100%;\n}\n\n.random-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n@media only screen and (max-width: 768px) {\n    .large-banner {\n        height: 430px;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
