// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .unauthorized-container h1 {
    color: #dc3545;
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }
  
  .unauthorized-container p {
    font-size: 1.25rem;
    color: #6c757d;
    margin-bottom: 2rem;
  }
  
  .return-button {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .return-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Unauthorized.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".unauthorized-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #f8f9fa;\n    text-align: center;\n  }\n  \n  .unauthorized-container h1 {\n    color: #dc3545;\n    margin-bottom: 1rem;\n    font-size: 2.5rem;\n  }\n  \n  .unauthorized-container p {\n    font-size: 1.25rem;\n    color: #6c757d;\n    margin-bottom: 2rem;\n  }\n  \n  .return-button {\n    background-color: #007bff;\n    color: white;\n    padding: 0.5rem 1rem;\n    border: none;\n    border-radius: 0.25rem;\n    font-size: 1.25rem;\n    cursor: pointer;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n  .return-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
