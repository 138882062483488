import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Subscribe.css"; // Import the CSS file
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const Subscribe = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const validateEmail = (email) => {
        // Basic email validation regex
        const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return re.test(email);
    };

    const handleSubmit = async () => {
        if (validateEmail(email)) {
            try {
                const response = await fetch("http://"+ backendHost + "/products/email-list", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                });

                if (response.ok) {
                    setMessage("E-mail cadastrado com sucesso!");
                    setEmail("");
                } else {
                    setMessage("Erro ao cadastrar e-mail. Tente novamente.");
                }
            } catch (error) {
                setMessage("Erro ao cadastrar e-mail. Tente novamente.");
            }
        } else {
            setMessage("Por favor, insira um e-mail válido.");
        }
    };

    return (
        <div className="subscribe-bar">
            <div className="subscribe-content">
                <div className="subscribe-text">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>Inscreva-se para receber nossas ofertas!</span>
                </div>
                <br />
                <div className="subscribe-form">
                    <input
                        type="email"
                        placeholder="Digite seu e-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <button onClick={handleSubmit}>Cadastrar</button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default Subscribe;
