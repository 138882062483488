// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxes {
    background-color: white;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin-bottom:40px;
}

@media only screen and (max-width: 768px) {
    .boxes {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".boxes {\n    background-color: white;\n    display: flex;\n    justify-content: center;\n    padding-bottom: 10px;\n    margin-bottom:40px;\n}\n\n@media only screen and (max-width: 768px) {\n    .boxes {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
