// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WelcomeBar.css */

.welcome-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    background-color: white;
    
  }
  
  .icon-box {
    display: flex;
    align-items: center;
    background-color: #0074E8; /* Dark blue background color */
    border-radius: 15px; /* Round borders */
    padding: 15px;
    margin-bottom: 20px;
    
}
  
  .home-icon {
    color: #ffcc00;
    font-size: 36px;
    margin-right: 10px;
  }
  
  .box-text {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-top:15px !important;
  }
  
  .paragraph-text {
    font-size: 1.3em;
    font-style: italic;
    width: 60%;
    text-align: center;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .italic {
    font-style: italic;
  }
  
  .larger-text {
    font-size: 1.2em;
  }
  
  .highlight {
    color: #ffcc00;

  }

  @media only screen and (max-width: 768px) {
    .icon-box {
        width:70%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/WelcomeBar.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,uBAAuB;;EAEzB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB,EAAE,+BAA+B;IAC1D,mBAAmB,EAAE,kBAAkB;IACvC,aAAa;IACb,mBAAmB;;AAEvB;;EAEE;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,0BAA0B;EAC5B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;;EAEhB;;EAEA;IACE;QACI,SAAS;IACb;AACJ","sourcesContent":["/* WelcomeBar.css */\n\n.welcome-bar {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    margin-top: 50px;\n    background-color: white;\n    \n  }\n  \n  .icon-box {\n    display: flex;\n    align-items: center;\n    background-color: #0074E8; /* Dark blue background color */\n    border-radius: 15px; /* Round borders */\n    padding: 15px;\n    margin-bottom: 20px;\n    \n}\n  \n  .home-icon {\n    color: #ffcc00;\n    font-size: 36px;\n    margin-right: 10px;\n  }\n  \n  .box-text {\n    font-size: 24px;\n    font-weight: bold;\n    color: white;\n    margin-top:15px !important;\n  }\n  \n  .paragraph-text {\n    font-size: 1.3em;\n    font-style: italic;\n    width: 60%;\n    text-align: center;\n  }\n  \n  .bold {\n    font-weight: bold;\n  }\n  \n  .italic {\n    font-style: italic;\n  }\n  \n  .larger-text {\n    font-size: 1.2em;\n  }\n  \n  .highlight {\n    color: #ffcc00;\n\n  }\n\n  @media only screen and (max-width: 768px) {\n    .icon-box {\n        width:70%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
