import React, { useState, useEffect } from "react";
import "../css/CategoriesList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const backendHost = process.env.REACT_APP_BACKEND_HOST;

const CategoriesList = ({ onCategorySelect }) => {
    const [categories, setCategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch("http://"+ backendHost + "/categories");
                if (response.ok) {
                    const data = await response.json();
                    setCategories(data);
                    // Automatically expand all categories on initial load
                    const initialExpandedCategories = data.map(category => Object.keys(category)[0]);
                    setExpandedCategories(initialExpandedCategories);
                } else {
                    console.error("Failed to fetch categories");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchCategories();
    }, []);

    const toggleCategory = (categoryName) => {
        setExpandedCategories((prevExpanded) =>
            prevExpanded.includes(categoryName)
                ? prevExpanded.filter((name) => name !== categoryName)
                : [...prevExpanded, categoryName]
        );
    };

    const handleCategoryClick = (categoryName) => {
        onCategorySelect(categoryName);
    };

    const renderCategory = (category, level = 0) => {
        const [name, children] = Object.entries(category)[0];
        const isExpanded = expandedCategories.includes(name);

        return (
            <div key={name} className="category-item" style={{ marginLeft: level * 15 }}>
                <div className="category-header">
                    <span onClick={() => handleCategoryClick(name)}>{name}</span>
                    {Array.isArray(children) && children.length > 0 && (
                        <span
                            className={`arrow ${isExpanded ? "open" : ""}`}
                            onClick={() => toggleCategory(name)}
                        >
                            {level === 0 && isExpanded ? "" : isExpanded 
                                ? <FontAwesomeIcon icon={faMinus} />
                                : <FontAwesomeIcon icon={faPlus} />
                            }
                        </span>
                    )}
                </div>
                {isExpanded && Array.isArray(children) && (
                    <div className="category-children">
                        {children.map((childCategory) =>
                            renderCategory(childCategory, level + 1)
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="categories-list">
            <div className="categories-container">
                {categories.map((category) => renderCategory(category))}
            </div>
        </div>
    );
};

export default CategoriesList;
