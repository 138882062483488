import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Unauthorized.css';

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/');
  };

  return (
    <div className="unauthorized-container">
      <h1>Não autorizado</h1>
      <p>Você não tem permissão para acessar essa página. Por favor retorne e realize o login.</p>
      <button className="return-button" onClick={handleReturn}>
        Retornar
      </button>
    </div>
  );
};

export default Unauthorized;
