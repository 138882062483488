// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .login-card {
    padding: 2rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    width: 300px;
  }
  
  .login-card .form-group {
    margin-bottom: 1.5rem;
  }
  
  .login-card .btn {
    width: 100%;
    cursor: pointer;
  }
  
  .login-card .btn:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,uBAAuB;IACvB,mDAAmD;IACnD,YAAY;EACd;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f8f9fa;\n  }\n  \n  .login-card {\n    padding: 2rem;\n    border: 1px solid #dee2e6;\n    border-radius: 0.25rem;\n    background-color: white;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);\n    width: 300px;\n  }\n  \n  .login-card .form-group {\n    margin-bottom: 1.5rem;\n  }\n  \n  .login-card .btn {\n    width: 100%;\n    cursor: pointer;\n  }\n  \n  .login-card .btn:disabled {\n    background-color: #6c757d;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
