// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RedButton.css */

.red-button {
    background-color: #9b1d22; /* Red background color */
    color: white; /* White text color */
    padding: 10px 24px; /* Vertical and horizontal padding */
    border: none;
    border-radius: 5px; /* Border radius */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 12px; /* Font size */
    text-align: center; /* Center text horizontally */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RedButton.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,yBAAyB,EAAE,yBAAyB;IACpD,YAAY,EAAE,qBAAqB;IACnC,kBAAkB,EAAE,oCAAoC;IACxD,YAAY;IACZ,kBAAkB,EAAE,kBAAkB;IACtC,eAAe,EAAE,4BAA4B;IAC7C,eAAe,EAAE,cAAc;IAC/B,kBAAkB,EAAE,6BAA6B;EACnD","sourcesContent":["/* RedButton.css */\n\n.red-button {\n    background-color: #9b1d22; /* Red background color */\n    color: white; /* White text color */\n    padding: 10px 24px; /* Vertical and horizontal padding */\n    border: none;\n    border-radius: 5px; /* Border radius */\n    cursor: pointer; /* Pointer cursor on hover */\n    font-size: 12px; /* Font size */\n    text-align: center; /* Center text horizontally */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
