// Company.jsx
import React from 'react';
import './Company.css'; // Import the CSS file

const backendHost = process.env.REACT_APP_BACKEND_HOST;

const Categorias = () => {
  return (
    <div className="company-container">
      <h2 className="company-title">Categorias</h2>
      <ul className="company-links">
        <li><a href={`http://${backendHost}/produtos/Descartáveis`}>Descartáveis</a></li>
        <li><a href={`http://${backendHost}/produtos/Papéis`}>Papéis</a></li>
        <li><a href={`http://${backendHost}/produtos/Dispensadores`}>Dispensadores</a></li>
        <li><a href={`http://${backendHost}/produtos/Higiene e Limpeza`}>Higiene e Limpeza</a></li>
        <li><a href={`http://${backendHost}/produtos/Sacos de Lixo e Lixeiras`}>Sacos de Lixo / Lixeiras</a></li>
        <li><a href={`http://${backendHost}/produtos/Segurança`}>Segurança</a></li>
        <li><a href={`http://${backendHost}/produtos/EPI´s`}>EPI's</a></li>
        <li><a href={`http://${backendHost}/produtos/Lançamentos`}>Lançamentos</a></li>
        <li><a href={`http://${backendHost}/produtos/Promoções`}>Promoções</a></li>
      </ul>
    </div>
  );
};

export default Categorias;
