// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Encontrar.css */

.encontrar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .encontrar-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .encontrar-image {
    width: 620px;

  }
  

  @media only screen and (max-width: 768px) { 
    .encontrar-container { 
      width: 100%;
    }

    .encontrar-image {
      width: 100%;
  
    }

  }`, "",{"version":3,"sources":["webpack://./src/components/Encontrar.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,YAAY;;EAEd;;;EAGA;IACE;MACE,WAAW;IACb;;IAEA;MACE,WAAW;;IAEb;;EAEF","sourcesContent":["/* Encontrar.css */\n\n.encontrar-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .encontrar-title {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .encontrar-image {\n    width: 620px;\n\n  }\n  \n\n  @media only screen and (max-width: 768px) { \n    .encontrar-container { \n      width: 100%;\n    }\n\n    .encontrar-image {\n      width: 100%;\n  \n    }\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
