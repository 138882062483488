import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Container } from 'react-bootstrap';
import '../css/RelatedProducts.css';
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const RelatedProducts = ({ productName }) => {
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [images, setImages] = useState({}); // State to hold images for related products

    useEffect(() => {
        const fetchRelatedProducts = async () => {
            try {
                const cleanedName = productName.replace(/\//g, '');
                const response = await fetch("http://"+ backendHost + `/products/search/${cleanedName}`);
                if (response.ok) {
                    const products = await response.json();
                    setRelatedProducts(products.slice(0, 4)); // Get the first 5 products
                    
                    // Fetch images for each related product
                    products.slice(0, 5).forEach(async (product) => {
                        const imagesResponse = await fetch("http://"+ backendHost + `/products/${product.id}/images`);
                        if (imagesResponse.ok) {
                            const imagesData = await imagesResponse.json();
                            setImages((prevImages) => ({
                                ...prevImages,
                                [product.id]: imagesData[0] // Assume the first image is the main one
                            }));
                        } else {
                            console.error('Images not found for product:', product.nome);
                        }
                    });
                } else {
                    console.error('Error fetching related products');
                }
            } catch (error) {
                console.error('Error fetching related products:', error);
            }
        };

        fetchRelatedProducts();
    }, [productName]);

    return (
        <Container className="related-products">
            <h3>_______     Produtos Relacionados     _______</h3>
            <Row className="related-products-row">
                {relatedProducts.map((product) => (
                    <Col key={product.id} md={4}>
                        <Card className="related-product-card">
                            <Link className="link-to-related-product" to={`/produto/${product.path}`}>
                                {images[product.id] ? (
                                    <Card.Img
                                        variant="top"
                                        src={"http://"+ backendHost + `${images[product.id]}`}
                                        alt={product.nome}
                                    />
                                ) : (
                                    <Card.Img variant="top" src="/path/to/placeholder.jpg" alt="Placeholder" />
                                )}
                                <Card.Body>
                                    <Card.Title>{product.nome}</Card.Title>
                                    <button>Ver produto</button>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default RelatedProducts;
