import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/ProductListCustomer.css";
import CategoriesList from "./CategoriesList";
import SocialBar from "./SocialBar";
import Subscribe from "./Subscribe";
import Footer from "./Footer";
import EndlessText from "./EndlessText.js";
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const ProductListCustomer = () => {
    const { categoryParam, searchParam } = useParams(); // Get the optional parameters from the URL
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(categoryParam || null); 
    const [currentProducts, setCurrentProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [noProductsFound, setNoProductsFound] = useState(false); // Add no products found state
    const navigate = useNavigate();

    const productsPerPage = 32;

    const fetchProducts = async (category = null, search = null) => {
        setLoading(true); // Set loading to true when starting fetch
        setNoProductsFound(false); // Reset no products found state
        try {
            let url = "http://"+ backendHost + "/products";
            if (search) {
                url = "http://"+ backendHost + `/products/search/${search}`;
            } else if (category) {
                url = "http://"+ backendHost + `/products/category/${category}`;
            }
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                setProducts(data);
                setTotalPages(Math.ceil(data.length / productsPerPage));
                setCurrentPage(1);
                setNoProductsFound(data.length === 0); // Set no products found based on data length
            } else {
                console.error("Failed to fetch products");
                setNoProductsFound(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setNoProductsFound(true);
        } finally {
            setLoading(false); // Set loading to false when fetch is complete
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProducts(selectedCategory, searchParam);
    }, [selectedCategory, searchParam]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * productsPerPage;
        const currentProductsSlice = products.slice(
            startIndex,
            startIndex + productsPerPage
        );

        const fetchImagesForCurrentProducts = async () => {
            const productsWithImages = await Promise.all(
                currentProductsSlice.map(async (product) => {
                    const imagesResponse = await fetch(
                        "http://"+ backendHost + `/products/${product.id}/images`
                    );
                    if (imagesResponse.ok) {
                        const imagesData = await imagesResponse.json();
                        return { ...product, images: imagesData };
                    }
                    return { ...product, images: [] };
                })
            );
            setCurrentProducts(productsWithImages);
        };

        fetchImagesForCurrentProducts();
    }, [currentPage, products]);

    const handleClickProduct = (path) => {
        navigate(`/produto/${path}`);
    };

    const handlePageChange = (page) => {
        window.scrollTo(0, 0);
        setCurrentPage(page);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${
                        currentPage === i ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    if (loading) {
        return <div className="loading">Carregando...</div>;
    }

    return (
        <div className="main-container">
            <div className="product-list-customer-container">
                <CategoriesList onCategorySelect={handleCategorySelect} />
                <div className="product-list-customer">
                    {noProductsFound && (
                        <h2 className="no-products-message">
                            Não encontramos nenhum produto para a pesquisa desejada. Por favor tente outros termos.
                        </h2>
                    )}
                    <div className="product-grid">
                        {currentProducts.map((product) => (
                            <div
                                key={product.id}
                                className="product-item"
                                onClick={() => handleClickProduct(product.path)}
                            >
                                {product.images.length > 0 ? (
                                    <img
                                        src={"http://"+ backendHost + `${product.images[0]}`}
                                        alt={product.nome}
                                        className="product-image"
                                    />
                                ) : (
                                    <div className="placeholder-image">
                                        No Image
                                    </div>
                                )}
                                <h2>{product.nome}</h2>
                                <p>{product.marca}</p>
                            </div>
                        ))}
                    </div>
                    <div className="pagination">{renderPagination()}</div>
                </div>
            </div>
            <Subscribe />
            <SocialBar />
            <Footer />
            <EndlessText></EndlessText>
        </div>
    );
    
};

export default ProductListCustomer;
