// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */

.footer-container {
    background-color: #fff; /* White background */
    padding: 20px;
    text-align: center; /* Center content horizontally */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* Adjust layout for desktop and mobile view */
@media only screen and (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center; /* Center content horizontally on small screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,sBAAsB,EAAE,qBAAqB;IAC7C,aAAa;IACb,kBAAkB,EAAE,gCAAgC;IACpD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA,8CAA8C;AAC9C;IACI;QACI,aAAa;QACb,sBAAsB,EAAE,4CAA4C;QACpE,mBAAmB,EAAE,iDAAiD;IAC1E;AACJ","sourcesContent":["/* Footer.css */\n\n.footer-container {\n    background-color: #fff; /* White background */\n    padding: 20px;\n    text-align: center; /* Center content horizontally */\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n/* Adjust layout for desktop and mobile view */\n@media only screen and (max-width: 768px) {\n    .footer-container {\n        display: flex;\n        flex-direction: column; /* Stack items vertically on small screens */\n        align-items: center; /* Center content horizontally on small screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
