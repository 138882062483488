// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories-list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f8f9fa;
    max-width: 500px;
    min-width:300px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.category-box {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-top:5px;
    transition: background-color 0.3s ease;
}

.category-header:hover {
    background-color: #e9ecef;
}

.category-name {
    font-size: 1.1em;
    font-weight: 500;
    color: #333;
}

.arrow {
    font-size: 0.9em;
    transition: transform 0.3s ease;
    margin-right: 10px;
    color:rgb(0, 21, 255) !important;
}

.arrow.open {
    transform: rotate(180deg);
}

.category-children {
    margin-top: 10px;
    padding-left: 5px;
}

.category-child {
    margin-bottom: 5px;
}

.unclickable {
    cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/css/CategoriesList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,+BAA+B;IAC/B,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".categories-list {\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    background-color: #f8f9fa;\n    max-width: 500px;\n    min-width:300px;\n    margin-right: 20px;\n    border-radius: 10px;\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\n}\n\n.category-box {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    margin-bottom: 10px;\n    padding: 10px;\n    background-color: #ffffff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.category-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n    margin-top:5px;\n    transition: background-color 0.3s ease;\n}\n\n.category-header:hover {\n    background-color: #e9ecef;\n}\n\n.category-name {\n    font-size: 1.1em;\n    font-weight: 500;\n    color: #333;\n}\n\n.arrow {\n    font-size: 0.9em;\n    transition: transform 0.3s ease;\n    margin-right: 10px;\n    color:rgb(0, 21, 255) !important;\n}\n\n.arrow.open {\n    transform: rotate(180deg);\n}\n\n.category-children {\n    margin-top: 10px;\n    padding-left: 5px;\n}\n\n.category-child {\n    margin-bottom: 5px;\n}\n\n.unclickable {\n    cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
