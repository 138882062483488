import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./css/App.css";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Empresa from "./components/Empresa";
import Encontrar from "./components/Encontrar";
import FaleConosco from "./components/FaleConosco";
import Login from "./components/Login";
import MainMenu from "./components/MainMenu";
import ListagemProdutos from "./components/ListagemProdutos";
import ProductView from "./components/ProductView";
import ProductPage from "./components/ProductPage";
import ProductListCustomer from "./components/ProductListCustomer";
import ScrollToTop from "./scrollToTop";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("jbUserLog") === "true"
    );

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("jbUserLog");
    };

    return (
        <Router>
            <ScrollToTop />
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/empresa" element={<Empresa />} />
                <Route path="/encontrar" element={<Encontrar />} />
                <Route path="/faleconosco" element={<FaleConosco />} />
                <Route
                    path="/painel-administrativo"
                    element={
                        isLoggedIn ? (
                            <MainMenu onLogout={handleLogout} />
                        ) : (
                            <Login onLogin={handleLogin} />
                        )
                    }
                />
                <Route
                    path="/lista-de-produtos"
                    element={
                        isLoggedIn ? (
                            <ListagemProdutos />
                        ) : (
                            <Login onLogin={handleLogin} />
                        )
                    }
                />
                <Route
                    path="/product/:id"
                    element={
                        isLoggedIn ? (
                            <ProductView />
                        ) : (
                            <Login onLogin={handleLogin} />
                        )
                    }
                />
                <Route path="/produto/:id" element={<ProductPage />} />
                <Route path="/inserir-produto" element={<ProductView />} />

                {/* Updated Route for ProductListCustomer to accept optional category and search parameters */}
                <Route
                    path="/produtos/:categoryParam?/:searchParam?"
                    element={<ProductListCustomer />}
                />
            </Routes>
        </Router>
    );
}

export default App;
