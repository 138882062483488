import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/ProductView.css";
import "quill/dist/quill.snow.css"; // Import Quill styles
import Quill from "quill";
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const ProductView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProduct, setEditedProduct] = useState({
        nome: "",
        marca: "",
        url: "",
        descricao: "",
        breadcrumbs: ["", "", ""],
    });
    const [file, setFile] = useState(null);
    const [categories, setCategories] = useState([[], [], []]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesResponse = await fetch(
                    "http://"+ backendHost + "/simplecategories"
                );
                if (categoriesResponse.ok) {
                    const categoriesData = await categoriesResponse.json();
                    setCategories(categoriesData);
                } else {
                    console.error("Categories not found");
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        if (id) {
            const fetchProductData = async () => {
                try {
                    const productResponse = await fetch(
                        "http://"+ backendHost + `/products/${id}`
                    );
                    if (productResponse.ok) {
                        const productData = await productResponse.json();
                        setProduct(productData);
                        setEditedProduct(productData);
                    } else {
                        console.error("Product not found");
                    }

                    const imagesResponse = await fetch(
                        "http://"+ backendHost + `/products/${id}/images`
                    );
                    if (imagesResponse.ok) {
                        const imagesData = await imagesResponse.json();
                        setImages(imagesData);
                    } else {
                        console.error("Images not found");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            };
            fetchProductData();
        }

        fetchCategories();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct({ ...editedProduct, [name]: value });
    };

    const handleBreadcrumbChange = (index, value) => {
        const updatedBreadcrumbs = [...editedProduct.breadcrumbs];
        updatedBreadcrumbs[index] = value;
        setEditedProduct({ ...editedProduct, breadcrumbs: updatedBreadcrumbs });
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedProduct(
            product || {
                nome: "",
                marca: "",
                url: "",
                descricao: "",
                breadcrumbs: ["", "", ""],
            }
        );
    };

    const handleSave = async () => {
      try {
          let response;
          if (id) {
              // Update existing product
              response = await fetch("http://"+ backendHost + `/products/products/${id}`, {
                  method: "PUT",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify(editedProduct),
              });
          } else {
              // Create new product
              response = await fetch("http://"+ backendHost + "/products/products", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify(editedProduct),
              });
          }
  
          if (response.ok) {
              const savedProduct = await response.json();
              setProduct(savedProduct);
              setIsEditing(false);
              navigate("/lista-de-produtos"); // Navigate to listagemprodutos after saving
          } else {
              console.error("Error saving product");
          }
      } catch (error) {
          console.error("Error saving product:", error);
      }
  };
  

  const handleBack = () => {
    if (!id) {
        navigate("/painel-administrativo"); // Navigate to the main menu if creating a new product
    } else {
        navigate("/lista-de-produtos"); // Navigate to the product list if editing an existing product
    }
};


    const handleDeleteImage = async (filename) => {
        if (window.confirm("Are you sure you want to delete this image?")) {
            try {
                const response = await fetch(
                    "http://"+ backendHost + `/products/products/${id}/images`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ filename }),
                    }
                );
                if (response.ok) {
                    const imagesResponse = await fetch(
                        "http://"+ backendHost + `/products/products/${id}/images`
                    );
                    if (imagesResponse.ok) {
                        const imagesData = await imagesResponse.json();
                        setImages(imagesData);
                    }
                } else {
                    console.error("Error deleting image");
                }
            } catch (error) {
                console.error("Error deleting image:", error);
            }
        }
    };

    const handleFileUpload = async () => {
        if (file) {
            const base64Data = await convertToBase64(file);
            try {
                const response = await fetch(
                    "http://"+ backendHost + `/products/products/${id}/upload`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            filename: file.name,
                            data: base64Data,
                        }),
                    }
                );
                if (response.ok) {
                    const productResponse = await fetch(
                        "http://"+ backendHost + `/products/${id}`
                    );
                    if (productResponse.ok) {
                        const productData = await productResponse.json();
                        setProduct(productData);
                        const imagesResponse = await fetch(
                            "http://"+ backendHost + `/products/${id}/images`
                        );
                        if (imagesResponse.ok) {
                            const imagesData = await imagesResponse.json();
                            setImages(imagesData);
                        }
                    }
                } else {
                    console.error("Error uploading image");
                }
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    return (
        <div className="product-view">
            <div className="product-content">
                <h1>
                    Nome do produto: <br />
                    {id ? (
                        isEditing ? (
                            <input
                                type="text"
                                name="nome"
                                value={editedProduct.nome}
                                onChange={handleInputChange}
                            />
                        ) : (
                            editedProduct.nome || "Novo Produto"
                        )
                    ) : (
                        <input
                            type="text"
                            name="nome"
                            value={editedProduct.nome}
                            onChange={handleInputChange}
                        />
                    )}
                </h1>
                <div className="product-details">
                    <div className="product-images">
                        {images.map((image, index) => (
                            <div key={index} className="image-container">
                                <img
                                    src={"http://"+ backendHost + `${image}`}
                                    alt={`Product ${index}`}
                                    className="product-image"
                                />
                                {id && isEditing && (
                                    <button
                                        className="btn-delete-image"
                                        onClick={() =>
                                            handleDeleteImage(
                                                image.split("/").pop()
                                            )
                                        }
                                    >
                                        X
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="product-info">
                        <p>
                            <strong>Marca:</strong>{" "}
                            {id ? (
                                isEditing ? (
                                    <input
                                        type="text"
                                        name="marca"
                                        value={editedProduct.marca}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    editedProduct.marca
                                )
                            ) : (
                                <input
                                    type="text"
                                    name="marca"
                                    value={editedProduct.marca}
                                    onChange={handleInputChange}
                                />
                            )}
                        </p>
                        <p className={isEditing && !id ? "hidden" : ""}>
                            <strong
                                className={!id ? "hidden" : ""}
                            >
                                Url:
                            </strong>
                            {isEditing ? (
                                <input
                                    type="text"
                                    name="url"
                                    value={editedProduct.url}
                                    onChange={handleInputChange}
                                    disabled
                                    className="readonly-input"
                                />
                            ) : (
                                <a
                                    href={editedProduct.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {editedProduct.url}
                                </a>
                            )}
                        </p>

                        <p>
                            <strong>Descrição:</strong>{" "}
                            {id ? (
                                isEditing ? (
                                    <textarea
                                        name="descricao"
                                        value={editedProduct.descricao}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    editedProduct.descricao
                                )
                            ) : (
                                <textarea
                                    name="descricao"
                                    value={editedProduct.descricao}
                                    onChange={handleInputChange}
                                />
                            )}
                        </p>
                        <p>
                            <strong>Categorias:</strong>{" "}
                            {id
                                ? isEditing
                                    ? categories.map((categoryList, index) => (
                                          <select
                                              key={index}
                                              value={
                                                  editedProduct.breadcrumbs[
                                                      index
                                                  ] || ""
                                              }
                                              onChange={(e) =>
                                                  handleBreadcrumbChange(
                                                      index,
                                                      e.target.value
                                                  )
                                              }
                                          >
                                              <option value="">
                                                  Selecione
                                              </option>
                                              {categoryList.map(
                                                  (category, i) => (
                                                      <option
                                                          key={i}
                                                          value={category}
                                                      >
                                                          {category}
                                                      </option>
                                                  )
                                              )}
                                          </select>
                                      ))
                                    : editedProduct.breadcrumbs.join(" > ")
                                : categories.map((categoryList, index) => (
                                      <select
                                          key={index}
                                          value={
                                              editedProduct.breadcrumbs[
                                                  index
                                              ] || ""
                                          }
                                          onChange={(e) =>
                                              handleBreadcrumbChange(
                                                  index,
                                                  e.target.value
                                              )
                                          }
                                      >
                                          <option value="">Selecione</option>
                                          {categoryList.map((category, i) => (
                                              <option key={i} value={category}>
                                                  {category}
                                              </option>
                                          ))}
                                      </select>
                                  ))}
                        </p>
                    </div>
                </div>
                <div className="button-group">
                    {id ? (
                        isEditing ? (
                            <>
                                <button
                                    className="btn-save"
                                    onClick={handleSave}
                                >
                                    Salvar
                                </button>
                                <button
                                    className="btn-cancel"
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </button>
                            </>
                        ) : (
                            <button className="btn-edit" onClick={handleEdit}>
                                Editar
                            </button>
                        )
                    ) : (
                        <>
                            <button className="btn-save" onClick={handleSave}>
                                Salvar
                            </button>
                            <button
                                className="btn-cancel"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                        </>
                    )}
                    <button className="btn-back" onClick={handleBack}>
                        Voltar
                    </button>
                    {id && isEditing && (
                        <>
                            <input
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                style={{ display: "none" }}
                                id="file-upload"
                            />
                            <button
                                className="btn-upload"
                                onClick={() =>
                                    document
                                        .getElementById("file-upload")
                                        .click()
                                }
                            >
                                Selecionar imagem
                            </button>
                            <button
                                className="btn-upload"
                                onClick={handleFileUpload}
                                disabled={!file}
                            >
                                Enviar imagem
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductView;
