// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empresa-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10%;
  }

  .empresa-background {
    
  }
 
  
  .empresa-image img {
    width: 100%; /* Span the entire horizontal space */
    height: 200px; /* Fixed height */
    object-fit: cover; /* Prevent stretching */
  }
  
  .empresa-text {
    padding: 15px;
    text-align: center; /* Horizontally center the text */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Empresa.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;EACb;;EAEA;;EAEA;;;EAGA;IACE,WAAW,EAAE,qCAAqC;IAClD,aAAa,EAAE,iBAAiB;IAChC,iBAAiB,EAAE,uBAAuB;EAC5C;;EAEA;IACE,aAAa;IACb,kBAAkB,EAAE,iCAAiC;EACvD","sourcesContent":[".empresa-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 10%;\n  }\n\n  .empresa-background {\n    \n  }\n \n  \n  .empresa-image img {\n    width: 100%; /* Span the entire horizontal space */\n    height: 200px; /* Fixed height */\n    object-fit: cover; /* Prevent stretching */\n  }\n  \n  .empresa-text {\n    padding: 15px;\n    text-align: center; /* Horizontally center the text */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
