// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Company.css */

.company-container {
    text-align: center; /* Center content horizontally */
    margin-left:60px;
    margin-right:60px;
  }
  
  .company-title {
    color: black; /* Black color for the title */
    font-weight: bold; /* Bold font for the title */
  }
  
  .company-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .company-links li {
    margin: 10px 0;
  }
  
  .company-links a {
    color: #6d6d6d; /* Dark grey color for the links */
    text-decoration: none;
  }
  
  .company-links a:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Company.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,kBAAkB,EAAE,gCAAgC;IACpD,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,YAAY,EAAE,8BAA8B;IAC5C,iBAAiB,EAAE,4BAA4B;EACjD;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc,EAAE,kCAAkC;IAClD,qBAAqB;EACvB;;EAEA;IACE,0BAA0B,EAAE,2BAA2B;EACzD","sourcesContent":["/* Company.css */\n\n.company-container {\n    text-align: center; /* Center content horizontally */\n    margin-left:60px;\n    margin-right:60px;\n  }\n  \n  .company-title {\n    color: black; /* Black color for the title */\n    font-weight: bold; /* Bold font for the title */\n  }\n  \n  .company-links {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .company-links li {\n    margin: 10px 0;\n  }\n  \n  .company-links a {\n    color: #6d6d6d; /* Dark grey color for the links */\n    text-decoration: none;\n  }\n  \n  .company-links a:hover {\n    text-decoration: underline; /* Add underline on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
