// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ListProducts.css */

.list-products {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
}

.list-products > * {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(20% - 15px); /* Adjust flex basis for 4 products per row */
  margin-right: 15px;
  margin-bottom: 15px;
  border: 2px solid transparent; /* Add border */
  transition: border-color 0.3s; /* Add transition for border color */
}

.list-products > *:hover {
  border-color: black; /* Change border color on hover */
}

h1, h2, h4 {
  margin-left: 10px;
}

h4 {
  color: rgb(123, 123, 123);
  font-weight: 400;
}

.breadcrumbs-container {
  border: 2px solid #ccc; /* Add border to container */
  padding: 10px;
  margin-top: 20px; /* Add margin to separate from products */
}

.breadcrumbs-list {
  list-style: none; /* Remove default list style */
  padding: 0;
}

.breadcrumb-item {
  cursor: pointer;
  margin-bottom: 5px; /* Add margin between breadcrumbs */
}

.breadcrumb-item:hover {
  background-color: #f0f0f0; /* Add background color on hover */
}

@media only screen and (max-width: 768px) {
  .list-products {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ListProducts.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B,EAAE,6CAA6C;EACzE,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B,EAAE,eAAe;EAC9C,6BAA6B,EAAE,oCAAoC;AACrE;;AAEA;EACE,mBAAmB,EAAE,iCAAiC;AACxD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB,EAAE,4BAA4B;EACpD,aAAa;EACb,gBAAgB,EAAE,yCAAyC;AAC7D;;AAEA;EACE,gBAAgB,EAAE,8BAA8B;EAChD,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,kBAAkB,EAAE,mCAAmC;AACzD;;AAEA;EACE,yBAAyB,EAAE,kCAAkC;AAC/D;;AAEA;EACE;IACE,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":["/* ListProducts.css */\n\n.list-products {\n  display: flex;\n  flex-wrap: wrap;\n  padding-left: 100px;\n  padding-right: 100px;\n}\n\n.list-products > * {\n  flex: 1 0 calc(20% - 15px); /* Adjust flex basis for 4 products per row */\n  margin-right: 15px;\n  margin-bottom: 15px;\n  border: 2px solid transparent; /* Add border */\n  transition: border-color 0.3s; /* Add transition for border color */\n}\n\n.list-products > *:hover {\n  border-color: black; /* Change border color on hover */\n}\n\nh1, h2, h4 {\n  margin-left: 10px;\n}\n\nh4 {\n  color: rgb(123, 123, 123);\n  font-weight: 400;\n}\n\n.breadcrumbs-container {\n  border: 2px solid #ccc; /* Add border to container */\n  padding: 10px;\n  margin-top: 20px; /* Add margin to separate from products */\n}\n\n.breadcrumbs-list {\n  list-style: none; /* Remove default list style */\n  padding: 0;\n}\n\n.breadcrumb-item {\n  cursor: pointer;\n  margin-bottom: 5px; /* Add margin between breadcrumbs */\n}\n\n.breadcrumb-item:hover {\n  background-color: #f0f0f0; /* Add background color on hover */\n}\n\n@media only screen and (max-width: 768px) {\n  .list-products {\n    display: flex;\n    flex-wrap: wrap;\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
