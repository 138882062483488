// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
  }
  
  .slider img {
    width: 100%;
    border-radius:10px;
  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  margin-top:0;
  }
  
  .custom-dot-list-style button{
  border: none;
  background: rgb(196, 196, 196);
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background:#0074E8 !important;
  }

  h2 {
    margin-bottom:0; 
  }`, "",{"version":3,"sources":["webpack://./src/components/MultiCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;EACA,sBAAsB;EACtB,YAAY;EACZ;;EAEA;EACA,YAAY;EACZ,8BAA8B;EAC9B;EACA;EACA,6BAA6B;EAC7B;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".slider {\n    margin:0 20px;\n    overflow:\"hidden\";\n    padding:2rem 0;\n  }\n  \n  .slider img {\n    width: 100%;\n    border-radius:10px;\n  }\n  \n  .react-multi-carousel-list {\n  padding: 0rem 0 2rem 0;\n  margin-top:0;\n  }\n  \n  .custom-dot-list-style button{\n  border: none;\n  background: rgb(196, 196, 196);\n  }\n  .react-multi-carousel-dot.react-multi-carousel-dot--active button{\n  background:#0074E8 !important;\n  }\n\n  h2 {\n    margin-bottom:0; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
