import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import duramax from '../resources/banners/duramax.jpeg';
import jimovidros from '../resources/banners/jimovidros.jpeg';
import lixeira from '../resources/banners/lixeira.jpeg';
import luvas from '../resources/banners/luvas.jpeg';
import higiene from "../resources/carousel/higiene.jpg";
import papeis from "../resources/carousel/papeis.jpg";
import descartaveis from "../resources/carousel/descartaveis.jpg";
import lixeiras from "../resources/carousel/lixeiras.jpg";
import dispensadores from "../resources/carousel/dispensadores.jpg";
import "./Carrousel.css";

const Carrousel = () => {
    const imagesMobile = [jimovidros, lixeira, luvas, duramax];
    const imagesDesktop = [higiene, papeis, descartaveis, lixeiras, dispensadores];
    const [randomImage, setRandomImage] = useState(null);
    const [category, setCategory] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const selectedImages = window.innerWidth <= 768 ? imagesMobile : imagesDesktop;
        const randomIndex = Math.floor(Math.random() * selectedImages.length);
        setRandomImage(selectedImages[randomIndex]);
        setCategory(getCategoryFromImage(selectedImages[randomIndex]));
    }, []);

    const getCategoryFromImage = (image) => {
        switch (image) {
            case higiene:
                return "Higiene e Limpeza";
            case papeis:
                return "Papéis";
            case descartaveis:
                return "Descartáveis";
            case lixeiras:
                return "Lixeiras";
            case dispensadores:
                return "Dispensadores";
            default:
                return null;
        }
    };

    const handleClick = () => {
        if (category) {
            navigate(`/produtos/${category}`);
        }
    };

    return window.innerWidth <= 768 ? (<div></div>) : (
        <div className="large-banner" onClick={handleClick}>
            {randomImage && (
                <img
                    src={randomImage}
                    alt="Random Banner"
                    className="random-image"
                />
            )}
        </div>
    );
};

export default Carrousel;
