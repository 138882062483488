// NavBar.jsx
import React, { useState } from "react";
import "./NavBar.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../resources/logojb.png";
import { Link } from "react-router-dom"; // Import Link

const backendHost = process.env.REACT_APP_BACKEND_HOST;

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchText, setSearchText] = useState(""); // Add state for search input

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Function to handle search redirect
    const handleSearch = () => {
        if (searchText.trim()) { // Check if search text is not empty
            window.location.href = `/produtos/buscar/${encodeURIComponent(searchText.trim())}`;
        }
    };

    // Function to handle key press in search input
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && searchText.trim()) { // Check if search text is not empty
            handleSearch();
        }
    };

    return (
        <div className={`home-container${isMenuOpen ? " menu-open" : ""}`}>
            {/* Original Navbar */}
            <nav className="navbar">
                <a href="/">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </a>
                <div className={`links${isMenuOpen ? " mobile-menu" : ""}`}>
                    <Link
                        id="RouterNavLink"
                        style={{ textDecoration: "none" }}
                        to="/empresa"
                    >
                        A Empresa
                    </Link>
                    {/*<Link
                        id="RouterNavLink"
                        style={{ textDecoration: "none" }}
                        to="/produtos"
                    >
                        Produtos
                    </Link> */}
                    <a href={`/produtos`}>Produtos</a>
                    <a href="#">Para seu negócio</a>
                    <Link
                        id="RouterNavLink"
                        style={{ textDecoration: "none" }}
                        to="/encontrar"
                    >
                        Onde encontrar
                    </Link>
                    <Link
                        id="RouterNavLink"
                        style={{ textDecoration: "none" }}
                        to="/faleconosco"
                    >
                        Fale Conosco
                    </Link>
                </div>
                <div className={`search${isMenuOpen ? " mobile-menu" : ""}`}>
                    <input
                        type="text"
                        placeholder="Buscar..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown} // Add onKeyDown handler
                    />
                    <button onClick={handleSearch}>
                        <i className="fa fa-magnifying-glass"></i> Buscar
                    </button>
                </div>
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                </div>
            </nav>

            {/* Mobile Menu Section */}
            {isMenuOpen && (
                <div className="mobile-menu-section">
                    {/* Mobile Menu Contents */}
                    <div className="links">
                        <Link
                            id="RouterNavLink"
                            style={{ textDecoration: "none" }}
                            to="/empresa"
                            onClick={toggleMenu}
                        >
                            A Empresa
                        </Link>
                        <Link
                            id="RouterNavLink"
                            style={{ textDecoration: "none" }}
                            to="/produtos"
                            onClick={toggleMenu}
                        >
                            Produtos
                        </Link>
                        <a href="#">Para seu negócio</a>
                        <Link
                            id="RouterNavLink"
                            style={{ textDecoration: "none" }}
                            to="/encontrar"
                            onClick={toggleMenu}
                        >
                            Onde encontrar
                        </Link>
                        <Link
                            id="RouterNavLink"
                            style={{ textDecoration: "none" }}
                            to="/faleconosco"
                            onClick={toggleMenu}
                        >
                            Fale Conosco
                        </Link>
                    </div>
                    <div className="search">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={handleKeyDown} // Add onKeyDown handler
                        />
                        <button onClick={handleSearch}>
                            <i className="fa fa-magnifying-glass"></i> Buscar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;
