import React from 'react';
import { useNavigate } from 'react-router-dom';
import Unauthorized from './Unauthorized';
import '../css/MainMenu.css';

const MainMenu = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('jbUserLog') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('jbUserLog');
    navigate('/');
  };

  const goToListagemProdutos = () => {
    navigate('/lista-de-produtos');
  };

  const goToCreateProduct = () => {
    navigate('/inserir-produto'); // Navigate to ProductView for creating a new product
  };

  if (!isLoggedIn) {
    return <Unauthorized />;
  }

  return (
    <div className="menu-container">
      <div className="menu-header">
        <h1>Bem vindo, administrador</h1>
        <button className="btn btn-danger logout-button" onClick={handleLogout}>
          Logout <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
      <div className="menu-options">
        <div className="menu-item" onClick={goToListagemProdutos}>
          Listagem de produtos
        </div>
        <div className="menu-item" onClick={goToCreateProduct}>
          Cadastro de produtos
        </div>
        <div className="menu-item">Exportar produtos</div>
      </div>
    </div>
  );
};

export default MainMenu;
