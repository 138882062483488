// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IconBar.css */

.icon-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 190px;
    background-color: white; /* Set the background color to white */
    overflow-x: auto; /* Allow horizontal scrolling on mobile */
    padding: 5px; /* Add padding to reduce the space between icons */
    
}

.icon {
    text-align: center;
    min-width: 80px;
    max-width: 80px; /* Maximum width for each icon */
    padding: 50px; /* Add padding around each icon */
    box-sizing: initial;
}

.icon-image {
    max-height: 80px; /* Maximum height for each icon */
    max-width: 100%;
    border-radius: 50%; /* Make the border circular */
    border: 2px solid #888; /* Set a solid grey border around each icon */
}

.icon-title {
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    
    color: #333; /* Customize the text color */
    margin-top: 5px;
    font-size: 14px; /* Customize the font size */
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .icon-bar {
        padding-left:360px;
    }
    .icon {
        padding:5px;
        margin-left:40px;
        box-sizing: initial;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/IconBar.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uBAAuB,EAAE,sCAAsC;IAC/D,gBAAgB,EAAE,yCAAyC;IAC3D,YAAY,EAAE,kDAAkD;;AAEpE;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,eAAe,EAAE,gCAAgC;IACjD,aAAa,EAAE,iCAAiC;IAChD,mBAAmB;AACvB;;AAEA;IACI,gBAAgB,EAAE,iCAAiC;IACnD,eAAe;IACf,kBAAkB,EAAE,6BAA6B;IACjD,sBAAsB,EAAE,6CAA6C;AACzE;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;;IAEnB,WAAW,EAAE,6BAA6B;IAC1C,eAAe;IACf,eAAe,EAAE,4BAA4B;AACjD;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,WAAW;QACX,gBAAgB;QAChB,mBAAmB;IACvB;AACJ","sourcesContent":["/* IconBar.css */\n\n.icon-bar {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    height: 190px;\n    background-color: white; /* Set the background color to white */\n    overflow-x: auto; /* Allow horizontal scrolling on mobile */\n    padding: 5px; /* Add padding to reduce the space between icons */\n    \n}\n\n.icon {\n    text-align: center;\n    min-width: 80px;\n    max-width: 80px; /* Maximum width for each icon */\n    padding: 50px; /* Add padding around each icon */\n    box-sizing: initial;\n}\n\n.icon-image {\n    max-height: 80px; /* Maximum height for each icon */\n    max-width: 100%;\n    border-radius: 50%; /* Make the border circular */\n    border: 2px solid #888; /* Set a solid grey border around each icon */\n}\n\n.icon-title {\n    font-family: 'Roboto', sans-serif;\n    white-space: nowrap;\n    \n    color: #333; /* Customize the text color */\n    margin-top: 5px;\n    font-size: 14px; /* Customize the font size */\n}\n\na {\n    text-decoration: none;\n}\n\n@media only screen and (max-width: 768px) {\n    .icon-bar {\n        padding-left:360px;\n    }\n    .icon {\n        padding:5px;\n        margin-left:40px;\n        box-sizing: initial;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
