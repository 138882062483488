// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/EndlessText.css */
.endless-text {
    background-color: #0074E8;
    text-align: center;
    padding: 20px;
    margin: 0 auto; /* Center the component */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .innerdiv {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
  }
  
  .endless-text p {
    color:rgb(255, 255, 255);
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  

  @media only screen and (max-width: 768px) { 
    .innerdiv {
      max-width: 90%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/EndlessText.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,cAAc,EAAE,yBAAyB;IACzC,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;EAErB;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;;EAGA;IACE;MACE,cAAc;IAChB;EACF","sourcesContent":["/* src/components/EndlessText.css */\n.endless-text {\n    background-color: #0074E8;\n    text-align: center;\n    padding: 20px;\n    margin: 0 auto; /* Center the component */\n    display:flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n  }\n\n  .innerdiv {\n    display:flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    max-width: 50%;\n  }\n  \n  .endless-text p {\n    color:rgb(255, 255, 255);\n    font-size: 12px;\n    line-height: 1.5;\n    margin-bottom: 15px;\n  }\n  \n\n  @media only screen and (max-width: 768px) { \n    .innerdiv {\n      max-width: 90%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
