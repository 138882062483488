import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/ListagemProdutos.css";
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const ListagemProdutos = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const productsPerPage = 30;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch("http://"+ backendHost + "/products");
                if (response.ok) {
                    const data = await response.json();
                    setProducts(data);
                    setFilteredProducts(data);
                } else {
                    console.error("Failed to fetch products");
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        const filtered = products.filter((product) => {
            const searchTerms = searchTerm.toLowerCase().split(" ");
            return searchTerms.every(
                (term) =>
                    product.nome.toLowerCase().includes(term) ||
                    product.marca.toLowerCase().includes(term) ||
                    product.descricao.toLowerCase().includes(term) ||
                    product.breadcrumbs.some((b) =>
                        b.toLowerCase().includes(term)
                    )
            );
        });
        setFilteredProducts(filtered);
        setCurrentPage(1);
    }, [searchTerm, products]);

    const handleDelete = async (id) => {
        if (
            window.confirm(
                "Tem certeza de que deseja excluir este produto? Esta ação é definitiva."
            )
        ) {
            try {
                await fetch("http://"+ backendHost + `/products/${id}`, {
                    method: "DELETE",
                });
                const updatedProducts = products.filter(
                    (product) => product.id !== id
                );
                setProducts(updatedProducts);
                setFilteredProducts(updatedProducts);
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        }
    };

    const handleEdit = (id) => {
        navigate(`/product/${id}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLogout = () => {
        localStorage.removeItem("jbUserLog");
        navigate("/");
    };

    const handleBack = () => {
        navigate("/painel-administrativo");
    };

    const startIndex = (currentPage - 1) * productsPerPage;
    const paginatedProducts = filteredProducts
        .slice()
        .reverse() // Reverse the array
        .slice(startIndex, startIndex + productsPerPage);

    return (
        <div className="listagem-produtos">
            <div className="listagem-header">
                <h1>Listagem de Produtos</h1>
                <input
                    type="text"
                    placeholder="Buscar..."
                    className="search-bar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="buttons-left">
                    <button className="btn-back" onClick={handleBack}>
                        Voltar
                    </button>
                    <button className="btn-logout" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Marca</th>
                        <th>Página do produto</th>
                        <th>Descrição</th>
                        <th>Categoriass</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedProducts.map((product, index) => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.nome}</td>
                            <td>{product.marca}</td>
                            <td>
                                <a
                                    href={product.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {product.url}
                                </a>
                            </td>
                            <td dangerouslySetInnerHTML={{ __html: product.descricao }}></td>
                            <td>{product.breadcrumbs.join(" > ")}</td>
                            <td>
                                <button
                                    className="btn-edit"
                                    onClick={() => handleEdit(product.id)}
                                >
                                    Editar
                                </button>
                                <button
                                    className="btn-delete"
                                    onClick={() => handleDelete(product.id)}
                                >
                                    Excluir
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                {Array.from(
                    {
                        length: Math.ceil(
                            filteredProducts.length / productsPerPage
                        ),
                    },
                    (_, index) => (
                        <button
                            key={index + 1}
                            className={
                                currentPage === index + 1 ? "active" : ""
                            }
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    )
                )}
            </div>
        </div>
    );
};

export default ListagemProdutos;
