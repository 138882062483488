import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
const backendHost = process.env.REACT_APP_BACKEND_HOST;

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const isFormValid = username.trim() !== '' && password.trim() !== '';

  const handleLogin = async () => {
    if (!isFormValid) return;

    try {
      const response = await fetch('http://localhost:5000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        document.cookie = `authToken=${data.token}; path=/`; // Save token to cookies
        localStorage.setItem('jbUserLog', 'true');
        onLogin();
        navigate('/painel-administrativo');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && isFormValid) {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h3 className="text-center">Admin Login</h3>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button
          className="btn btn-primary btn-block"
          onClick={handleLogin}
          disabled={!isFormValid}
        >
          <i className="fas fa-sign-in-alt"></i> Login
        </button>
      </div>
    </div>
  );
};

export default Login;
