// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SocialBar.css */

.social-bar {
    text-align: center;
    padding: 20px;
    background-color: #333; /* You can customize the background color */
    color: white; /* You can customize the text color */
  }
  
  .social-bar p {
    margin-bottom: 10px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icons a {
    color: white; /* You can customize the icon color */
    font-size: 24px; /* You can customize the icon size */
    text-decoration: none;
  }
  
  /* Add hover styles if needed */
  .social-icons a:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SocialBar.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB,EAAE,2CAA2C;IACnE,YAAY,EAAE,qCAAqC;EACrD;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,YAAY,EAAE,qCAAqC;IACnD,eAAe,EAAE,oCAAoC;IACrD,qBAAqB;EACvB;;EAEA,+BAA+B;EAC/B;IACE,YAAY;EACd","sourcesContent":["/* SocialBar.css */\n\n.social-bar {\n    text-align: center;\n    padding: 20px;\n    background-color: #333; /* You can customize the background color */\n    color: white; /* You can customize the text color */\n  }\n  \n  .social-bar p {\n    margin-bottom: 10px;\n  }\n  \n  .social-icons {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n  }\n  \n  .social-icons a {\n    color: white; /* You can customize the icon color */\n    font-size: 24px; /* You can customize the icon size */\n    text-decoration: none;\n  }\n  \n  /* Add hover styles if needed */\n  .social-icons a:hover {\n    opacity: 0.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
