// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  position: relative;
  max-height: 200px;
  overflow: hidden;
}

.carousel {
  display: flex;
  width: 100%;
}

.slide {
  flex: 0 0 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/InstaCarousel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,QAAQ;AACV","sourcesContent":[".carousel-container {\n  position: relative;\n  max-height: 200px;\n  overflow: hidden;\n}\n\n.carousel {\n  display: flex;\n  width: 100%;\n}\n\n.slide {\n  flex: 0 0 200px;\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.image {\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: cover;\n}\n\n.prev, .next {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  border: none;\n  cursor: pointer;\n  padding: 10px;\n  z-index: 1;\n}\n\n.prev {\n  left: 0;\n}\n\n.next {\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
